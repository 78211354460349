export const GameEnum = {
    MTG: "mtg",
    LORCANA: "lorcana",
    POKEMON: "pokemon",
} as const;

// Create a unified structure for each game
export const Game = {
    [GameEnum.MTG]: {
        value: "Magic",
        title: "Magic: The Gathering",
        links: {
            BASE: "/mtg",
            DECKS: "/mtg/decks",
            SETS: "/mtg/sets",
            POSTS: "/mtg/posts",
            CARDS: "/mtg/kaarten",
        },
        options: {
            HAS_GENERAL: true,
        }
    },
    [GameEnum.LORCANA]: {
        value: "Lorcana",
        title: "Disney Lorcana",
        links: {
            BASE: "/lorcana",
            DECKS: "/lorcana/decks",
            SETS: "/lorcana/sets",
            POSTS: "/lorcana/posts",
            CARDS: "/lorcana/kaarten",
        },
        options: {
            HAS_GENERAL: false,
        }
    },
    [GameEnum.POKEMON]: {
        value: "Pokemon",
        title: "Pokemon TCG",
        links: {
            BASE: "/pokemon",
            DECKS: "/pokemon/decks",
            SETS: "/pokemon/sets",
            POSTS: "/pokemon/posts",
            CARDS: "/pokemon/kaarten",
        },
        options: {
            HAS_GENERAL: false,
        }
    }
} as const;

type GamePath = keyof typeof Game[typeof GameEnum.MTG]['links'];

export function getGameLink(game: keyof typeof Game, path: GamePath): string {
    const gameInfo = Game[game];
    if (!gameInfo) {
        throw new Error(`Invalid game slug: ${game}`);
    }

    const link = gameInfo.links[path];
    if (!link) {
        throw new Error(`Invalid path: ${path} for game: ${game}`);
    }

    return link;
}

type GameOptionKeys<T extends keyof typeof Game> = keyof (typeof Game[T])['options'];
export function getGameOption<T extends keyof typeof Game>(
    gameSlug: T,
    optionKey: GameOptionKeys<T>
) {
    const gameOptions = Game[gameSlug].options;
    return gameOptions[optionKey];
}
